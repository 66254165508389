import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		component: Home,
		children: [
			{
				path: "/",
				name: "首页",
				component: () => import("@/components/Home.vue"),
				meta: {
					title: "北京中航泊悦酒店",
				},
			},
			{
				path: "/overview",
				name: "酒店概况",
				component: () => import("../views/overview/index.vue"),
				meta: {
					title: "酒店概况-北京中航泊悦酒店",
				},
			},
			{
				path: "/allocation",
				name: "配套设施",
				component: () => import("../views/allocation/index.vue"),
				meta: {
					title: "配套设施-北京中航泊悦酒店",
				},
			},
			{
				path: "/booking",
				name: "客房预订",
				component: () => import("../views/roomReservation/index.vue"),
				meta: {
					title: "客房预订-北京中航泊悦酒店",
				},
			},
			{
				path: "/about",
				name: "联系我们",
				component: () => import("../views/relation/index.vue"),
				meta: {
					title: "联系我们-北京中航泊悦酒店",
				},
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

export default router;
