import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		room_info: {},
		nav_idx: 0,
	},
	getters: {},
	mutations: {
		setRoomInfo(state, room_info) {
			state.room_info = room_info;
		},
		setNavIdx(state, nav_idx) {
			state.nav_idx = nav_idx;
		},
	},
	actions: {},
	modules: {},
});
