<template>
	<div class="home">
		<Header @navHandelChange="navHandel" ref="header" />
		<router-view></router-view>
		<Footer
			@goAllocation="goAllocation"
			@goHome="goHome"
			@goRoom="goRoom"
			@goOverview="goOverview"
		/>
		<div class="tips">
			<div class="item">
				<div class="pre">
					<img src="../../assets/image/prev_icon.png" alt="" @click="top" />
				</div>
				<div class="next">
					<img src="../../assets/image/next_icon.png" alt="" @click="down" />
				</div>
			</div>
		</div>
		<div class="close" @click="closeBtn"></div>
		<div class="gotop" @click="goTop"></div>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
	name: "index",
	components: { Header, Footer },
	props: {},
	data() {
		return {
			navIndex: 0,
		};
	},
	watch: {
		"$store.state.nav_idx": {
			handler: function (newVal, oldVal) {
				this.$refs.header.navHandel(newVal);
			},
		},
	},
	methods: {
		navHandel(index) {
			this.navIndex = index;
		},
		goAllocation() {
			document.documentElement.scrollTop = 0;
			this.navIndex = 2;
			this.$store.commit("setNavIdx", 2);
		},
		goHome() {
			document.documentElement.scrollTop = 0;
			this.navIndex = 0;
			this.$store.commit("setNavIdx", 0);
		},
		goOverview() {
			document.documentElement.scrollTop = 0;
			this.navIndex = 0;
			this.$store.commit("setNavIdx", 1);
		},
		goRoom() {
			document.documentElement.scrollTop = 0;
			this.navIndex = 0;
			this.$store.commit("setNavIdx", 3);
		},
		toMore() {
			document.documentElement.scrollTop = 0;
			this.navIndex = 0;
			this.$store.commit("setNavIdx", 1);
		},
		closeBtn() {
			let close = document.getElementsByClassName("close")[0];
			let tips = document.getElementsByClassName("tips")[0];
			close.classList.toggle("active");
			tips.classList.toggle("active2");
		},
		goTop() {
			var height =
				document.documentElement.scrollTop || document.body.scrollTop;
			var t = setInterval(() => {
				height -= 50;
				if (height > 0) {
					window.scrollTo(0, height);
				} else {
					window.scrollTo(0, 0);
					clearInterval(t);
				}
			}, 10);
		},
		top() {
			var height =
				document.documentElement.scrollTop || document.body.scrollTop;
			if (height <= 0) {
				return false;
			} else {
				window.scrollTo(0, height - 700);
			}
		},
		down() {
			var height =
				document.documentElement.scrollTop || document.body.scrollTop;
			if (height >= 5400) {
				return false;
			} else {
				window.scrollTo(0, height + 700);
			}
		},
	},
};
</script>
<style scoped lang="scss">
.tips {
	position: fixed;
	right: -10px;
	top: 50%;
	margin-top: -179px;
	z-index: 10000;
	transition: all 0.3s;
	.item {
		width: 60px;
		height: 130px;
		background: #c1bfc0;
		border-radius: 10px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.pre {
			width: 100%;
			height: 20px;
			img {
				margin-right: 6px;
				margin-top: 10px;
				cursor: pointer;
			}
		}
		.next {
			width: 100%;
			height: 20px;
			img {
				margin-right: 6px;
				margin-bottom: 10px;
				cursor: pointer;
			}
		}
	}
}
.close {
	width: 32px;
	height: 32px;
	position: fixed;
	background-image: url("../../assets/image/guanbi.png");
	top: 47%;
	right: 5px;
	margin-top: -10px;
	z-index: 10000;
	cursor: pointer;
	transition: all 0.3s;
}
.gotop {
	width: 60px;
	height: 60px;
	position: fixed;
	background-image: url("../../assets/image/huidaodingbu.png");
	background-size: cover;
	background-repeat: no-repeat;
	bottom: 0;
	right: 5px;
	margin-top: -10px;
	z-index: 10000;
	cursor: pointer;
	transition: all 0.3s;
}
.active {
	transform: rotateZ(45deg);
}
.active2 {
	transform: translateX(200px);
	transition: all 0.7s;
}
.reserve {
	width: 286px;
	height: 324px;
	background-color: #888888;
	position: fixed;
	top: -50%;
	left: -50%;
}
</style>
