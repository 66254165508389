<template>
  <div>
    <div class="header">
      <div class="hea-bg"></div>
      <div class="header-content">
        <div class="header-item">
          <div class="item-left">
            <img src="../assets/boyue_logo.png" alt="" />
          </div>
          <div class="item-right">
            <p class="number">订房电话: 010-64689999</p>
            <div class="footer">
              <div
                v-for="(item, index) in navList"
                :key="index"
                :ref="'navBtn' + index"
                class="navbtn"
                @click="navHandel(index, item)"
                @mouseenter="
                  (e) => {
                    mouseenter(e, item, index)
                  }
                "
                @mouseleave="
                  (e) => {
                    mouseleave(e, item, index)
                  }
                "
              >
                <span>{{ item.name }}</span>
                <img
                  src="../assets/image2/title_icon.png"
                  v-show="item.show"
                  alt="Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-container" id="mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="carous in bannerList" :key="carous.id">
          <img :src="carous.pic" />
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div> -->
      <!-- 如果需要滚动条
        <div class="swiper-scrollbar"></div> -->
    </div>
  </div>
</template>

<script>
  import Swiper from 'swiper'
  import 'swiper/css/swiper.css'
  export default {
    name: 'Header',
    components: {},
    props: {},
    data() {
      return {
        navList: [
          {
            name: '首页',
            show: true,
            isBind: true,
            id: 1,
          },
          {
            name: '酒店概况',
            show: false,
            isBind: false,
            id: 2,
          },
          {
            name: '配套设施',
            show: false,
            isBind: false,
            id: 3,
          },
          {
            name: '客房预订',
            show: false,
            isBind: false,
            id: 4,
          },
          {
            name: '联系我们',
            show: false,
            isBind: false,
            id: 5,
          },
        ],
        bannerList: [
          { pic: require('../assets/swiper/swiper_1.jpg'), id: 1 },
          { pic: require('../assets/swiper/swiper_2.jpg'), id: 2 },
          { pic: require('../assets/swiper/swiper_3.jpg'), id: 3 },
          { pic: require('../assets/swiper/swiper_4.jpg'), id: 4 },
          { pic: require('../assets/swiper/swiper_5.jpg'), id: 5 },
          { pic: require('../assets/swiper/swiper_6.jpg'), id: 6 },
          { pic: require('../assets/swiper/swiper_7.jpg'), id: 7 },
        ],
        effectList: ['slide', 'fade', 'coverflow'],
        pathList: ['/', '/overview', '/allocation', '/booking', '/about'],
      }
    },
  mounted() {
      let idx = this.pathList.findIndex(item=>this.$route.path == item)
      if (idx) {
        this.$refs['navBtn' + idx][0].style.color = '#B79223'
        this.navList.forEach((item, index) => {
          if (idx != index) {
            item.show = false
            item.isBind = false
            this.$refs['navBtn' + index][0].style.color = '#000000'
          } else {
            item.show = true
            item.isBind = true
          }
        })
        this.$emit('navHandelChange', idx)
      }
      var mySwiper = new Swiper('.swiper-container', {
        loop: true, // 循环模式选项
        effect: this.effectList[
          Math.floor(Math.random() * this.effectList.length)
        ],
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
        },

        // // 如果需要前进后退按钮
        // navigation: {
        // nextEl: '.swiper-button-next',
        // prevEl: '.swiper-button-prev',
        // },
        // 如果需要滚动条
        // scrollbar: {
        // el: '.swiper-scrollbar',
        // },
        autoplay: {
          delay: 3000,
          // 用户操作不停止自动播放
          disableOnInteraction: false,
        }, //1000，即1秒切换一次
      })
    },
    methods: {
      mouseenter(e, item, index) {
        if (!item.isBind) {
          item.show = true
        }
      },
      mouseleave(e, item, index) {
        if (!item.isBind) {
          item.show = false
        }
      },
      navHandel(idx) {
        this.$refs['navBtn' + idx][0].style.color = '#B79223'
        this.navList.forEach((item, index) => {
          if (idx != index) {
            item.show = false
            item.isBind = false
            this.$refs['navBtn' + index][0].style.color = '#000000'
          } else {
            item.show = true
            item.isBind = true
          }
        })
        this.$emit('navHandelChange', idx)
        this.$router.push(this.pathList[idx])
        // localStorage.navHandel = idx
        this.$store.commit('setNavIdx',idx)
      },
    },
  }
</script>
<style scoped lang="scss">
  $base-color: #b79223;
  .header {
    width: 100%;
    box-shadow: 0px 2px 13px #dfdbdb;
    .header-content {
      width: 1200px;
      margin: 0 auto;
      .header-item {
        height: 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          line-height: 10px;
          color: $base-color;
          font-size: 16px;
          p {
            margin: 16px 0;
          }
          img {
            width: 200px;
            height: 70px;
          }
        }
        .item-right {
          display: flex;
          height: 100%;
          flex-direction: column;
          align-items: flex-end;
          .number {
            color: $base-color;
            font-size: 20px;
            padding-right: 10px;
          }
          p {
            margin: 20px 0 !important;
          }
          .footer {
            span {
              font-size: 18px;
            }
            .navbtn {
              width: 110px;
              display: block;
              float: left;
              margin: 0 20px;
              font-size: 20px;
              line-height: 30px;
              &:hover {
                color: $base-color;
                cursor: pointer;
              }
              &:first-child {
                color: $base-color;
              }
              &:last-child {
                margin-right: 10px;
              }
              img {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
  .swiper-container {
    width: 1900px;
    margin-top: 20px;
    img {
      width: 100%;
      height: 620px;
    }
  }
</style>
