var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"customModuleRow"},[_c('div',{staticClass:"customModuleRowInner"},[_c('div',{staticClass:"footer-inner"},[_c('div',{staticClass:"left"},[_c('h5',{staticClass:"ev-text-title-5"},[_vm._v("网站导航")]),_c('p',[_c('span',{on:{"click":_vm.shouye}},[_vm._v("网站首页")]),_c('span',{on:{"click":_vm.shijing}},[_vm._v("实景展示")])]),_c('p',[_c('span',{on:{"click":_vm.room}},[_vm._v("酒店房间")]),_c('span',{on:{"click":_vm.overview}},[_vm._v("酒店概况")])])]),_c('div',{staticClass:"center"},[_c('h5',{staticClass:"ev-text-title-5"},[_vm._v("联系我们")]),_c('div',{ref:"concat"},[_vm._m(0),_vm._m(1),_vm._m(2)])]),_vm._m(3)])])]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_c('img',{attrs:{"src":require("../assets/image/rexian.png"),"alt":""}})]),_c('span',[_vm._v("订房热线：010-64689999")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_c('img',{attrs:{"src":require("../assets/image/dizhi.png"),"alt":""}})]),_c('span',[_vm._v("联系地址：北京市朝阳区霄云路36号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_c('img',{attrs:{"src":require("../assets/image/xinfeng.png"),"alt":""}})]),_c('span',[_vm._v("联系邮箱：bjbyc.reservation.public@airchinaboyue.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('h5',{staticClass:"ev-text-title-5"},[_vm._v("关注我们")]),_c('div',{staticClass:"follow"},[_c('img',{attrs:{"src":require("../assets/image2/wx_code.jpg")}}),_c('div',{staticClass:"text"},[_c('span',[_vm._v("扫一扫")]),_c('br'),_c('span',[_vm._v("关注我们")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customModuleRow footer-bar"},[_c('div',{staticClass:"customModuleRowInner"},[_c('div',{staticClass:"bar-center"},[_c('div',{staticClass:"copyright"},[_c('span',[_vm._v("COPYRIGHT (©) 2019 北京中航泊悦酒店版权所有")]),_c('span',{staticClass:"icp-num"},[_c('a',{attrs:{"href":"http://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("京ICP备14059324号")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customModuleRow footer-admin"},[_c('div',{staticClass:"customModuleRowInner"},[_c('div',{staticClass:"admin-center"},[_c('span',[_vm._v("技术支持： 杭州直订通科技有限公司 ")])])])])
}]

export { render, staticRenderFns }