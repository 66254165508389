import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import "animate.css";
import VueWechatTitle from "vue-wechat-title";
Vue.use(VueWechatTitle);
import {
	Row,
	Col,
	Form,
	Input,
	Button,
	Select,
	Skeleton,
	DatePicker,
	ConfigProvider,
} from "ant-design-vue";

Vue.config.productionTip = false;
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(Input);
Vue.use(Button);
Vue.use(Select);
Vue.use(Skeleton);
Vue.use(DatePicker);
Vue.use(ConfigProvider);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
