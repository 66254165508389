<template>
  <div class="footer">
    <div class="customModuleRow">
      <div class="customModuleRowInner">
        <div class="footer-inner">
          <div class="left">
            <h5 class="ev-text-title-5">网站导航</h5>
            <p>
              <span @click="shouye">网站首页</span>
              <span @click="shijing">实景展示</span>
            </p>
            <p>
              <span @click="room">酒店房间</span>
              <span @click="overview">酒店概况</span>
            </p>
          </div>
          <div class="center">
            <h5 class="ev-text-title-5">联系我们</h5>
            <div ref="concat">
              <p>
                <span>
                  <img src="../assets/image/rexian.png" alt="">
                </span>
                <span>订房热线：010-64689999</span>
              </p>
              <p>
                <span>
                  <img src="../assets/image/dizhi.png" alt="">
                </span>
                <span>联系地址：北京市朝阳区霄云路36号</span>
              </p>
              <p>
                <span>
                  <img src="../assets/image/xinfeng.png" alt="">
                </span>
                <span>联系邮箱：bjbyc.reservation.public@airchinaboyue.com</span>
              </p>
            </div>
          </div>
          <div class="right">
            <h5 class="ev-text-title-5">关注我们</h5>
            <div class="follow">
              <img src="../assets/image2/wx_code.jpg" />
              <div class="text">
                <span>扫一扫</span><br>
                <span>关注我们</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="customModuleRow footer-bar">
      <div class="customModuleRowInner">
        <div class="bar-center">
          <div class="copyright">
            <span>COPYRIGHT (©) 2019 北京中航泊悦酒店版权所有</span>
            <span class="icp-num"><a href="http://beian.miit.gov.cn/" target="_blank">京ICP备14059324号</a></span>
          </div>
        </div>
      </div>
    </div>
    <div class="customModuleRow footer-admin">
      <div class="customModuleRowInner">
        <div class="admin-center">
          <span>技术支持： 杭州直订通科技有限公司 </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
  props: {},
  data () {
    return {
    }
  },
  computed: {},
  watch: {},
  created () {
    this.listenerFunction();
  },
  mounted () {},
  destroyed () {},
  methods: {
    listenerFunction(e) {
      document.addEventListener('scroll', this.handleScroll, true)
    },
    handleScroll () {
      let ht = window.pageYOffset
      if(ht >= 5270){
        this.$refs.concat.classList.add('animated','lightSpeedIn','delay-0.5s')
      }
    },
    manageLogin(){
      window.open('http://ebooking.zhidingtong.com/','_blank')
    },
    shijing(){
      this.$emit('goAllocation')
    },
    shouye(){
      this.$emit('goHome')
    },
    room(){
      this.$emit('goRoom')
    },
    overview(){
      this.$emit('goOverview')
    }
  }
}

</script>
<style scoped lang="scss">
.position-ab{
  position: absolute;
  top: 0;
  left: 0;
}
.ev-text-title-3 {
    font-size: 26px;
    font-weight: 400;
    line-height: 1.3;
}
.ev-text-title-4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
}
.ev-text-title-5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
}
.customModuleRow{
  margin: 0 auto;
  padding: 0;
  background-color: #1E1E1E;
}
.customModuleRowInner{
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.footer-inner{
  height: 205px;
  padding:20px 0;
  display: flex;
  justify-content: space-between;
  h5 {
      margin: 30px 0;
      color: #fff;
  }
  .left{
    color: #fff;
    text-align: left;
    p{
      color: #CCC;
      span{
        padding-right: 40px;
        cursor: pointer;

        &:hover{
          color: #CE7728;
          text-decoration: underline;
        }
      }
    }
  }
  .center{
    color: #fff;
    text-align: left;
     p{
      color: #CCC;
      span{
        padding-right: 10px;
      }
    }
  }
  .right{
    color: #fff;
    text-align: left;
    .follow{
      width: 200px;
      height: 106px;
      background-image: url(../assets/image/biankuang.png);
      background-repeat: no-repeat;
      img{
        width: 96px;
        height: 96px;
        float: left;
        position: relative;
        top: 2px;
        left: 3px;
      }
      .text{
        padding-top: 30px;
        padding-left: 118px;
        color: #fff;
      }
    }
  }
}
.footer-bar{
  background-color: #171717;
  .bar-center{
    width: 100%;
    height: 40px;
    line-height: 42px;
    text-align: center;
    font-size: 14px;
    color: #CBCBCB;
    .copyright{
      text-align: center;
      a{
        text-decoration: none;
        color: #CBCBCB;
        &:hover{
          color: #CE7728;
        }
      }
      .icp-num{
        color: #444431;
        cursor: pointer;
        padding-left: 10px;
        &:hover{
          color: #CE7728;
        }
      }
    }
  }
}
.footer-admin{
  background-color: #fff;
  .admin-center{
    height: 30px;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    .login{
      cursor: pointer;
      &:hover{
        color: #CE7728;
      }
    }
  }
}
</style>
